import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2811543174/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Transactions`}</h1>
    <p>{`The Fidel API `}<inlineCode parentName="p">{`Transaction`}</inlineCode>{` object is the central piece of data in your card-linked application. When a user makes a purchase with a linked card in a program participating brand location, Fidel captures the transaction event in real-time. The Fidel API then sends the data to your server in JSON format through `}<a parentName="p" {...{
        "href": "/select/webhooks"
      }}>{`webhooks`}</a>{`.`}</p>
    <p>{`One transaction event occurs at authorisation time. The other transaction event occurs when the transaction has cleared. These are two distinct events, and Fidel processes both of them, even if you're not registering webhooks to listen for both event types. Because we bill based on transaction events, and we process both of them, you'll get charged for both of them, regardless of the number or type of webhooks you have registered with Fidel.`}</p>
    <h2>{`Transaction Object`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Field name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Type`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`accountId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique identifier of the user account at Fidel API.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`amount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The amount of the transaction in the currency it was charged in.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`approvalCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique code that is sent along with the authorization of the transaction by Amex. It mirrors the `}<inlineCode parentName="td">{`identifiers.amexApprovalCode`}</inlineCode>{` property.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string (or null)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`auth`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates whether Fidel API received an authorization event for this transaction.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`authCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique code that is sent along with the authorization of the transaction by Visa and Mastercard. It is sent by the issuer/the PCN on the issuer's behalf when approving a transaction. It mirrors the `}<inlineCode parentName="td">{`identifiers.mastercardAuthCode`}</inlineCode>{` or `}<inlineCode parentName="td">{`identifiers.visaAuthCode`}</inlineCode>{` properties, depending on the issuing card for the transaction.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string (or null)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`brand.id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier of the brand at Fidel API.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`brand.logoURL`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL of the logo for the brand.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string (or null)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`brand.name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the merchant where the purchase was made.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`brand.metadata`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customized information you added to the brand.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object (or null)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`card.firstNumbers`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The first numbers of the card, used for helping users identify their cards.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`card.id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The tokenized card identifier created by Fidel API.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`card.lastNumbers`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The last numbers of the card, used for helping users identify their cards.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`card.metadata`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customized information you added to the card.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object (or null)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`card.scheme`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The payment network (Visa, Mastercard or Amex).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cardPresent`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates whether the transaction was in store (as opposed to online). Only available in Visa transactions, and only if the transaction data received from Visa contains this information. In test transactions, this field is always null.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean (or null)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cleared`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates whether Fidel API received a clearing event for this transaction.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`created`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The time when the transaction object was created in the Fidel API database.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string, in `}<a parentName="td" {...{
              "href": "https://en.wikipedia.org/wiki/ISO_8601"
            }}>{`ISO 8601`}</a>{` format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`currency`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The currency of the purchase.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string, alpha code format of `}<a parentName="td" {...{
              "href": "https://en.wikipedia.org/wiki/ISO_4217"
            }}>{`ISO 4217`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`datetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The time of the transaction (local time of the merchant).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string, in `}<a parentName="td" {...{
              "href": "https://en.wikipedia.org/wiki/ISO_8601"
            }}>{`ISO 8601`}</a>{` format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique identifier of the transaction.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`identifiers`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`See the definition of the identifiers object below.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`location.address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The street address of the location where the purchase was made.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`location.city`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The city of the location where the purchase was made.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`location.countryCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The country code of the location where the purchase was made.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string, Alpha3 format of `}<a parentName="td" {...{
              "href": "https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes"
            }}>{`ISO 3166-1`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`location.`}</inlineCode>{` `}<inlineCode parentName="td">{`geolocation.latitude`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The latitude of the location where the purchase was made.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`location.`}</inlineCode>{` `}<inlineCode parentName="td">{`geolocation.longitude`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The longitude of the location where the purchase was made.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`location.id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier of the location where the purchase was made.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`location.metadata`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Custom metadata that you added to the location.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object (or null)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`location.postcode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The postcode of the location where the purchase was made.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`location.timezone`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The time zone of the location where the purchase was made.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`merchantCategoryCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The merchant category code (MCC) provided by the card network. It is used to classify businesses by the types of goods provided or services rendered. May not be present in all transactions.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`offer.cashback`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The amount of money that can be refunded to the cardholder according to the offer.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`offer.id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier of the offer. The location where the transaction was made is linked to the offer. If the transaction qualifies for more than one offer, the one with highest reward for the cardholder is added to the transaction. If none of the offers apply, the most recently created is added to the transaction.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`offer.message`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the transaction doesn't qualify for the offer, the message field explains why.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string (or null)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`offer.performanceFee`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fee paid to Fidel API for the service.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`offer.qualificationDate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date when the offer will become qualified, depending on the return period. If the offer has a return period, transactions will only qualify for the offer after the return period has passed.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`offer.qualified`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates whether the transaction is qualified for the offer.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`programId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier of the Fidel API program that this transaction is linked to.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`refundTransactionId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier of the transaction that was refunded by this transaction, if applies.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updated`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time when this transaction was last time updated (authorized/cleared).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string, in `}<a parentName="td" {...{
              "href": "https://en.wikipedia.org/wiki/ISO_8601"
            }}>{`ISO 8601`}</a>{` format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`wallet`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This property has been deprecated since August 2019 because of privacy concerns, and will always return `}<inlineCode parentName="td">{`null`}</inlineCode>{` on transactions created after that date. If you're retrieving a transaction that was created before August 2019, the property could be one of: `}<inlineCode parentName="td">{`"apple-pay"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"google-pay"`}</inlineCode>{`, or `}<inlineCode parentName="td">{`"samsung-pay"`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`null`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The `}<inlineCode parentName="p">{`identifiers`}</inlineCode>{` object includes the following properties, all of them being of string type or null:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`identifiers.amexApprovalCode`}</inlineCode>{`: Unique code that is sent along with the authorization of the transaction by Amex. It mirrors the `}<inlineCode parentName="li">{`approvalCode`}</inlineCode>{` property.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`identifiers.mastercardAuthCode`}</inlineCode>{`: Unique code that is sent along with the authorization of the transaction by Mastercard. It mirrors the `}<inlineCode parentName="li">{`authCode`}</inlineCode>{` property.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`identifiers.mastercardRefNumber`}</inlineCode>{`: Unique identifier of the transaction provided by Mastercard. It is generated by Mastercard when the authorization request is received from the issuer. This is used by Fidel API to try to match a given clearing transaction with the corresponding authorization transaction.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`identifiers.mastercardTransactionSequenceNumber`}</inlineCode>{`: Transaction sequence number provided by Mastercard. This is generated by Mastercard to identify the sequence of the transaction for a given PAN in a calendar day. Available only in clearing transactions.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`identifiers.MID`}</inlineCode>{`: Card terminal identifier in a brand's location that may or may not be unique. MIDs can be different between auth and clearing.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`identifiers.visaAuthCode`}</inlineCode>{`: Unique code that is sent along with the authorization of the transaction by Visa. It mirrors the `}<inlineCode parentName="li">{`authCode`}</inlineCode>{` property.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`summary:Example of a transaction in JSON format (API version is 2022-07-13)
fileName:transaction.json
{
  "id": "7fdfd5d8-9589-402f-8477-4a727ad138a2",
  "accountId": "4ed4b72b-aa4c-43a1-8054-da6d1368e17a",
  "amount": 100,
  "approvalCode": "AA00BB",
  "auth": true,
  "authCode": "A73H890",
  "cardPresent": false,
  "cleared": false,
  "created": "2019-04-09T16:00:00.644Z",
  "currency": "GBP",
  "datetime": "2019-04-10T15:59:30",
  "programId": "6e38aa0c-b7ef-46bd-b1bd-c07c647d9cba",
  "refundTransactionId": null,
  "updated": "2019-04-09T16:00:00.644Z",
  "wallet": null,
  "brand": {
    "id": "9d136f2e-df99-4a08-a0a5-3bc1534b7db8",
    "logoURL": "https://example.com/logo.png",
    "name": "Coffee Brand",
    "metadata": null
  },
  "card": {
    "id": "bc538b71-31c5-4699-820a-6d4a08693314",
    "firstNumbers": "555500",
    "lastNumbers": "5001",
    "scheme": "visa",
    "metadata": {
      "id": "00012345"
    }
  },
  "identifiers": {
    "amexApprovalCode": "AA00BB",
    "mastercardAuthCode": null,
    "mastercardRefNumber": "AABBCCDDE",
    "mastercardTransactionSequenceNumber": "0000001234567",
    "MID": "8552067328",
    "visaAuthCode": "A73H890"
  },
  "location": {
    "id": "7a916fbd-70a0-462f-8dbc-bd7dbfbea140",
    "address": "53 Frith Street",
    "city": "London",
    "countryCode": "GBR",
    "postcode": "W1D 4SN",
    "timezone": "Europe/London",
    "geolocation": {
      "latitude": 51.513716,
      "longitude": -0.13202
    },
    "metadata": {
      "id": "0001234567",
      "name": "Coffee Brand HQ"
    }
  },
  "offer": {
    "qualified": true,
    "id": "eeefb94b-d11c-44db-81d7-d86a9fcc4069",
    "message": [],
    "qualificationDate": null,
    "cashback": 2.5,
    "performanceFee": 0.3
  },
}
`}</code></pre>
    <h2>{`Transaction Event Types`}</h2>
    <p>{`In the life cycle of a transaction, there are multiple transaction events. One transaction event occurs at authorization time. Another transaction event occurs when the transaction has cleared, meaning the funds were successfully moved. If a payment gets reimbursed, that results in a third event. These are distinct events, and Fidel API processes all of them, even if you're not registering webhooks to listen for all event types.`}</p>
    <p>{`Fidel API currently supports three types of transactions: `}<a parentName="p" {...{
        "href": "#authorization"
      }}>{`authorization transactions`}</a>{`, `}<a parentName="p" {...{
        "href": "#clearing"
      }}>{`clearing transactions`}</a>{` and `}<a parentName="p" {...{
        "href": "#refund"
      }}>{`refund transactions`}</a>{`.`}</p>
    <h2>{`Authorization`}</h2>
    <p>{`Authorization transactions are processed when a purchase is made on a linked card at a location (either in-store or online). For example, when a customer makes a purchase at a location with their linked card, the `}<inlineCode parentName="p">{`transaction.auth`}</inlineCode>{` webhook will be triggered and the transaction object will be sent to your specified URL in real time.`}</p>
    <h2>{`Clearing`}</h2>
    <p>{`Clearing transactions - also known as settled transactions - are processed when a payment transaction settles. This usually happens 48 to 72 hours after a payment is made. Clearing transactions trigger the `}<inlineCode parentName="p">{`transaction.clearing`}</inlineCode>{` webhook event.`}</p>
    <h2>{`Refund`}</h2>
    <p>{`Refund transactions are processed when a payment is refunded, i.e., when a purchased item is returned and the payment reverses. A refunded transaction triggers two webhook events: `}<inlineCode parentName="p">{`transaction.clearing`}</inlineCode>{` and `}<inlineCode parentName="p">{`transaction.refund`}</inlineCode>{`, with the `}<inlineCode parentName="p">{`auth`}</inlineCode>{` property set to `}<inlineCode parentName="p">{`false`}</inlineCode>{`. The amount on both events is negative. Fidel API tries to identify the initial transaction for which the refund was issued using `}<inlineCode parentName="p">{`cardId`}</inlineCode>{`, `}<inlineCode parentName="p">{`locationId`}</inlineCode>{`, `}<inlineCode parentName="p">{`merchantId`}</inlineCode>{`, `}<inlineCode parentName="p">{`amount`}</inlineCode>{` and `}<inlineCode parentName="p">{`datetime`}</inlineCode>{`. If an associated initial transaction is identified, the webhook data contains the `}<inlineCode parentName="p">{`originalTransactionId`}</inlineCode>{`. If no initial transaction is identified, the data comes in on both webhooks with a negative amount but no `}<inlineCode parentName="p">{`originalTransactionId`}</inlineCode>{` property.`}</p>
    <p>{`The original transaction has a `}<inlineCode parentName="p">{`refundTransactionId`}</inlineCode>{` property set to the `}<inlineCode parentName="p">{`transactionId`}</inlineCode>{` of the refunded transaction. Updates on the original transaction will not trigger a webhook event.`}</p>
    <p>{`If you need the updated information about the original transaction, you can retrieve it using the `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/get-transaction"
      }}>{`Get Transaction endpoint`}</a>{`, with the originalTransactionId from the refunded transaction object.`}</p>
    <div className="info-box">
  <small>Void Transactions</small><br />
  Voids are treated as refunds. So they will be processed as a refund transaction and trigger the <code>transaction.refund</code> webhook
    </div>
    <h2>{`Test Transactions`}</h2>
    <p>{`For testing purposes, you can use the `}<a parentName="p" {...{
        "href": "https://dashboard.fidel.uk/playground"
      }}><strong parentName="a">{`API Playground`}</strong></a>{` in the Fidel Dashboard test environment to create test transactions and test your application logic. Alternatively, you can use the `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/create-transaction-test"
      }}>{`Create Test Transaction`}</a>{` API endpoint to create authorisation test transaction. You can see an example implementation for creating and clearing test transactions via the API in our `}<a parentName="p" {...{
        "href": "https://github.com/Enigmatic-Smile/fidel-api-sample-app/blob/main/server/controllers/transactions.js"
      }}>{`sample application on GitHub`}</a>{`.`}</p>
    <p>{`To create a test transaction, you will need a `}<a parentName="p" {...{
        "href": "/select/programs"
      }}>{`Program`}</a>{`, a `}<a parentName="p" {...{
        "href": "/select/locations"
      }}>{`Location`}</a>{` and a test `}<a parentName="p" {...{
        "href": "/select/cards"
      }}>{`Card`}</a>{` linked to the program.`}</p>
    <h5>{`Create Test Transactions Using the API`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`curl -X POST \\
  https://api.fidel.uk/v1/transactions/test \\
  -H 'Content-Type: application/json' \\
  -H 'Fidel-Key: <KEY>' \\
  -d '{
    "amount": 10,
    "cardId": "bc538b71-31c5-4699-820a-6d4a08693314",
    "locationId": "7a916fbd-70a0-462f-8dbc-bd7dbfbea140"
  }'
`}</code></pre>
    <h5>{`Create Test Transactions Using the API Playground`}</h5>
    <p>{`On the Fidel Dashboard, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.fidel.uk/playground"
      }}><strong parentName="a">{`Playground`}</strong></a>{` option in the navigation menu. Click on the transactions `}<strong parentName="p">{`/create`}</strong>{` link, located on the left side, in the `}<strong parentName="p">{`Endpoints`}</strong>{` drawer menu. The method will be set to POST and the endpoint to `}<strong parentName="p"><em parentName="strong">{`/transactions/test`}</em></strong>{`. An editable sample JSON object like the following one will be used to create the transaction.`}</p>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/create-transaction.png",
        "alt": "Create transaction",
        "title": "Create transaction"
      }}></img></p>
    <p>{`To create a test transaction, use the dropdown menus to select the Program, Location and Card for the transaction. These selections will be used to populate the `}<inlineCode parentName="p">{`cardId`}</inlineCode>{`, `}<inlineCode parentName="p">{`locationId`}</inlineCode>{` and the `}<inlineCode parentName="p">{`amount`}</inlineCode>{` in the JSON payload. You can modify any of the properties in the JSON file (including the amount).`}</p>
    <p>{`Click `}<strong parentName="p">{`Run`}</strong>{` and a test authorisation transaction will be created. If the transaction is created successfully, you will see the transaction object in the Response body box. If you have registered a `}<inlineCode parentName="p">{`transaction.auth`}</inlineCode>{` webhook event for this program, the authorisation transaction object will be sent to your webhook URL as well.`}</p>
    <h5>{`Clear Test Transactions Using the Dashboard`}</h5>
    <p>{`To clear an authorisation test transaction, you can navigate to the `}<a parentName="p" {...{
        "href": "https://dashboard.fidel.uk/transactions"
      }}>{`Transaction`}</a>{` option in the Dashboard navigation menu. You'll see all your transactions listed there. Find the one you want to change the status of, click on the three dots on the right side of it. A popup menu will appear, and you should click on the 'Clear' option. This action will change the status of the transaction from `}<inlineCode parentName="p">{`auth`}</inlineCode>{` to `}<inlineCode parentName="p">{`cleared`}</inlineCode>{`, and trigger the `}<inlineCode parentName="p">{`transaction.clearing`}</inlineCode>{` webhook event.`}</p>
    <h2>{`API Reference`}</h2>
    <p>{`To find out more about our Transactions API and how to use it with your application, please visit the `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/create-transaction-test"
      }}>{`Fidel API Reference`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      